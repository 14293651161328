import { GA_TRACKING_ID } from 'src/config/generic';

declare global {
	interface Window {
		gtag: (...args: unknown[]) => void;
	}
}

export const pageview = (url: string) => {
	window.gtag('config', GA_TRACKING_ID as string, {
		page_path: url,
	});
};

interface EventProps {
	action: string;
	category: string;
	label: string;
	value: string;
}

export const event = ({ action, category, label, value }: EventProps) => {
	window.gtag('event', action, {
		event_category: category,
		event_label: label,
		value,
	});
};
