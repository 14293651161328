export interface Social {
	twitter: string;
	linkedin: string;
	instagram?: string;
	github?: string;
	facebook?: string;
}

export interface SiteMetaData {
	title: string;
	description: string;
	social: Social;
}

export const SITE_META_DATA: SiteMetaData = {
	title: `Medianique`,
	description: `Software Engineering`,
	social: {
		linkedin: `medianique`,
		twitter: `medianique`,
		facebook: `medianique`,
		github: `medianique`,
	},
};
