import { DefaultSeoProps } from 'next-seo';

import { FACEBOOK_APP_ID } from './generic';
import { BASE_URL } from './generic';
import { SITE_META_DATA } from './siteMetadata';

const { title, description } = SITE_META_DATA;

export const SEO: DefaultSeoProps = {
	title,
	description,
	facebook: {
		appId: FACEBOOK_APP_ID,
	},
	openGraph: {
		type: 'website',
		url: BASE_URL,
		title,
		description,
		images: [
			{
				url: `${BASE_URL}/images/og-image.jpg`,
				width: 400,
				height: 400,
				alt: title,
				type: 'image/jpeg',
			},
		],
		site_name: title,
	},
	twitter: {
		handle: SITE_META_DATA.social.twitter,
		site: BASE_URL,
		cardType: 'summary',
	},
};
