export const BASE_URL = 'https://medianique.nl';

export const CONTACT_EMAIL = 'info@medianique.nl';

export const GA_TRACKING_ID = 'G-3N0N71L65D';

export const FACEBOOK_APP_ID = '252767811445011';

export const META_KEYWORDS: string[] = [
	'apps',
	'software engineering',
	'it',
	'automatisering',
	'frank eijking',
	'anna van schelven',
	'karaoak',
	'pananna',
	'medianiek',
	'medianatic',
];
